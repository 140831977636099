import request from '@/utils/request'

// 走访记录一览
export function listPage(data) {
  return request({
    url: '/visitRecord/selectVisitPage',
    method: 'post',
    data
  })
}
//走访记录详情
export function getSelectById(query) {
  return request({
    url: '/visitRecord/selectById',
    method: 'get',
    params: query
  })
}
//下载文件
export function downloadFile(query) {
  return request({
    url: '/public/downloadFile',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}
// 导出走访记录
export function exportInterviewApi(query) {
  return request({
    url: '/visitRecord/exportInterviewLog',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}
// 诊断记录
export function getDiagnosticLogoutApi(query) {
  return request({
    url: '/visitRecord/getDiagnosticLogout',
    method: 'get',
    params: query
  })
}
// 导出诊断记录
export function exportDiagnoseGroupApi(query) {
  return request({
    url: '/visitRecord/exportDiagnoseGroup',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}
