<template>
  <div>
    <div class="card_list">
      <div class="card_item">
        <div class="card_top color_green" v-if="form.visitWay == '线上会议'">
          {{ form.visitWay }}
        </div>
        <div class="card_top color_blue" v-else-if="form.visitWay == '线下走访'">
          {{ form.visitWay }}
        </div>
        <div class="card_top color_purple" v-else-if="form.visitWay == '电话沟通'">
          {{ form.visitWay }}
        </div>
        <div class="card_left">
          <div class="img_box">
            <img v-if="form.companyLogo" :src="form.companyLogo" alt="" />
            <img v-else src="../../../../assets/image/enterprise-default-logo.png" alt="" />
          </div>
          <div class="info_box">
            <div class="row">
              <div class="enterprise_name">
                {{ form.companyFullName }}
              </div>
            </div>
            <div class="row">
              <div
                class="type_tag"
                v-for="(item, index) in newIndustryLabel(form.industryLabel).slice(0, 2)"
                :key="index"
              >
                {{ item }}
              </div>
              <el-popover placement="right" width="400" trigger="hover">
                <div class="row_tag">
                  <div
                    class="type_tag1"
                    v-for="(lo, lh) in newIndustryLabel(form.industryLabel).slice(2)"
                    :key="lh"
                  >
                    {{ lo }}
                  </div>
                </div>
                <div
                  class="type_tag"
                  slot="reference"
                  v-show="newIndustryLabel(form.industryLabel).length > 2"
                >
                  {{ newIndustryLabel(form.industryLabel).length - 2 }}+
                </div>
              </el-popover>
            </div>
            <div class="row">
              <div>
                <span>{{ form.district || '-' }}</span>
              </div>
              <span class="bottom_time">走访时间：{{ form.visitTime || '-' }}</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="box_list">
            <div class="item">
              <div class="row">
                <span class="txt">接待人</span>
              </div>
              <div class="num" v-if="form.receiverInfo">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">参与人</span>
              </div>
              <div class="num" v-if="form.participantInfo">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">需求信息</span>
              </div>
              <div class="num" v-if="form.requirementDescribe">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">解决方案</span>
              </div>
              <div class="num" v-if="form.solutionFile">有</div>
              <div class="num1" v-else>无</div>
            </div>
          </div>
          <div class="btn_box">
            <div class="set_manager">
              <span class="manager_name"> 客户经理： </span>
              <div class="middle_icon_box">
                <img v-if="form.customerHead" :src="form.customerHead" alt="" />
                <img v-else src="@/assets/image/wait-set-manager.png" alt="" />
              </div>
              <span class="manager_name">
                {{ form.customerName }}
              </span>
            </div>
            <div class="btn_list">
              <el-button type="primary" @click="toBack">返回一上级</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card_title">贵阳电信线下走访调研工具</div>
    <div class="card_time">
      <span>提交时间：{{ createTime || '-' }}</span>
    </div>
    <div class="card_conter">
      <div class="center_left">
        <div class="card_left">
          <div class="navigation_top">快速导航</div>
          <div class="navigation_content">
            <el-collapse accordion v-model="activeNames" @change="collChange">
              <div class="first_round"></div>
              <div class="second_round"></div>
              <!-- 一级问题组 collapse-item -->
              <el-collapse-item v-for="(item, index) in dataList" :key="index" :name="index">
                <template slot="title">
                  <div class="nav_level_one">
                    <div class="triangle_box" v-if="activeNames==index"></div>
                    <div v-else class="li_round"></div>
                    <!-- 一级问题组名称 -->
                    <span>{{ item.groupName }}</span>
                  </div>
                </template>
               <div v-if="item.results">
                <div
                  :class="tabindex == lh ? 'childback back' : 'childback'"
                  v-for="(li, lh) in item.results"
                  :key="lh"
                  @click="problemClick(li.vos,lh)"
                >
                  {{ li.groupName }}
                </div>
               </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <div class="center_right">
        <el-timeline>
          <el-timeline-item
            :timestamp="item.optionData"
            v-for="(item, index) in problemList"
            :key="index"
          >
            <div>
              <div class="right_title">{{ item.caption }}</div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <div class="new_page">
      <el-button class="btn_color" @click="toBack">返回上一级</el-button>
      <el-button type="primary" icon="el-icon-download" @click="exportDiagnoseGroup">诊断记录</el-button>
    </div>
  </div>
</template>
<script>
import { getDiagnosticLogoutApi, getSelectById, exportDiagnoseGroupApi } from '@/api/visiting'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      form: {},
      dataList: [],
      activeNames: 0,
      tabindex: 0,
      problemList: [],
      createTime: ''
    }
  },
  //监听属性 类似于data概念
  computed: {
    id() {
      return this.$route.query.id
    },
    diagnoseId() {
      return this.$route.query.diagnoseId
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 头部数据
    async searchHeader() {
      const res = await getSelectById({ id: this.id })
      if (res.resultCode === 200) {
        this.form = res.data
      } else {
        this.$message.warning('获取企业信息失败!')
      }
    },
    // 诊断记录数据
    async search() {
      const res = await getDiagnosticLogoutApi({ id: this.diagnoseId })
      if (res.resultCode == 200) {
        this.dataList = res.data
        this.problemList = this.dataList[this.activeNames].results[this.tabindex].vos
        this.createTime = res.data[0].results[0].vos[0].createTime
      } else {
        this.$message.warning('获取诊断信息失败!')
      }
    },
    collChange(){
      // console.log(this.activeNames);
      this.tabindex=0
      this.problemList = this.dataList[this.activeNames].results[this.tabindex].vos
    },
    // 右边数据渲染
    problemClick(val,i) {
      this.tabindex=i
      this.problemList = val
    },
    // 导出诊断记录
    async exportDiagnoseGroup() {
      const res = await exportDiagnoseGroupApi({ id: this.diagnoseId })
      if (res) {
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date

        a.download = `${nowtime} ${this.form.companyFullName}诊断记录.xlsx` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.warning('下载失败!')
      }
    },
    // 数据处理
    newIndustryLabel(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    // 返回上级
    toBack() {
      this.$router.go(-1)
    }
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {
    this.search()
    this.searchHeader()
  },
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {}
}
</script>
<style scoped lang="less">
::v-deep .el-timeline-item__timestamp {
  color: #8b8b8b;
  font-size: 16px;
}
::v-deep .el-timeline-item__timestamp.is-bottom {
  margin-top: 16px;
}
::v-deep .el-timeline-item__node {
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #448aff;
}
::v-deep .el-timeline-item__node--normal {
  left: -3px;
  width: 8px;
  height: 8px;
}
::v-deep .el-timeline-item__tail {
  position: absolute;
  left: 4px;
  height: 100%;
  border-left: 2px solid #448aff;
}

.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  .po_reportingStatus {
    position: absolute;
    color: #f53939;
    top: 50%;
    transform: translateY(-50%);
    left: 28%;
    z-index: 999 !important;
    img {
      width: 76px;
      height: 60px;
    }
  }
  .card_item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;
    .card_top {
      position: absolute;
      top: 10px;
      left: 6px;
      height: 30px;
      min-width: 84px;
      color: #ffffff;
      z-index: 99999;
      text-align: center;
      line-height: 30px;
      border-radius: 15px 20px 20px 0;
    }
    .card_left {
      display: flex;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
      width: 40%;
      margin-right: 20px;
      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .img_box {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        img {
          width: 100px;
          height: 100px;
        }
        .tag_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 20px;
          color: #fff;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          position: absolute;
          top: 10px;
          left: 0;
          z-index: 999;
        }
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        .bottom_time {
          margin-left: 20px;
        }
        .enterprise_name {
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          max-width: 340px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }

        .tag_list {
          display: flex;
          .tag_item {
            font-size: 10px;
            color: #4e93fb;
            border: #559eff 1px solid;
            border-radius: 4px;
            padding: 5px;
            margin-right: 6px;
          }

          .show_more {
            position: relative;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            cursor: pointer;
            .show_tag_box {
              position: absolute;
              top: 100%;
              left: 100%;
              z-index: 99;
              width: 240px;
              padding: 6px;
              box-sizing: border-box;
              border-radius: 4px;
              background-color: #fff;
              border: #f8f8f8 1px solid;
              display: flex;
              flex-flow: row wrap;
              li {
                padding: 3px 5px;
                .tag {
                  width: fit-content;
                  font-size: 10px;
                  line-height: 20px;
                  color: #4e93fb;
                  border: #559eff 1px solid;
                  border-radius: 4px;
                  padding: 0 6px;
                  box-sizing: border-box;
                }
              }
            }
          }
        }
      }
    }
    .card_right {
      flex: 1;
      display: flex;
      .box_list {
        display: flex;
        align-items: center;
        .item {
          display: flex;
          justify-content: center;
          text-align: center;
          margin-right: 30px;
          .num {
            cursor: pointer;
            font-weight: bold;
            font-size: 24px;
            color: #4e93fb;
            margin-bottom: 10px;
          }
          .num1 {
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            margin-bottom: 10px;
          }
          .txt {
            font-size: 16px;
            margin-right: 15px;
            color: #151515;
          }
        }
        // .item:hover {
        //   .num {
        //     color: #448aff;
        //   }
        //   .icon_box {
        //     color: #448aff;
        //   }
        //   span {
        //     color: #448aff;
        //   }
        // }
      }
      .btn_box {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 10px;
        .set_manager {
          display: flex;
          align-items: center;
          min-width: 120px;
          margin-right: 50px;
          .middle_icon_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #f4f4f4;
            overflow: hidden;
            margin-right: 10px;
            img {
              width: 35px;
              height: 35px;
            }
            i {
              font-size: 16px;
              color: #c9c9c9;
            }
          }
          .manager_name,
          .wait_set {
            cursor: pointer;
            font-size: 16px;
          }
        }
        .divs {
          margin-right: 10px;
        }
        .set_manager {
          display: flex;
          align-items: center;
          min-width: 120px;
          margin-right: 50px;
          .middle_icon_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #f4f4f4;
            overflow: hidden;
            margin-right: 10px;
            img {
              width: 35px;
              height: 35px;
            }
            i {
              font-size: 16px;
              color: #c9c9c9;
            }
          }
          .manager_name,
          .wait_set {
            cursor: pointer;
            font-size: 16px;
          }
        }
        .btn_list span {
          font-size: 14px;
        }
      }
    }
  }
}
.card_title {
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 550;
  color: #363e4d;
  height: 63px;
  line-height: 63px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  background-color: #deebff;
}
.card_time {
  width: 90%;
  height: 40px;
  margin: 0 auto;
  background-color: #ffffff;
  font-family: PingFang SC;
  font-size: 16px;
  color: #81868d;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    margin-right: 20px;
  }
}
.card_conter {
  background-color: #fff;
  width: 90%;
  height: calc(100vh - 291px - 103px);
  margin: 0 auto;
  display: flex;
  .center_left {
    min-width: 300px;
    height: calc(100vh - 291px - 103px);
    box-sizing: border-box;
    padding-top: 10px;
    margin-left: 30px;
    overflow-y: auto;
    /* 滚动条整体部分 */
    &::-webkit-scrollbar {
      width: 3px;
      height: 9px;
    }
    /* 滚动条里面的滑块 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      transition: background-color 0.3s;
    }
    .card_left {
      min-width: 300px;
      // width: 60%;
      margin: 0 auto;
      align-items: center;
      border: 0.5px solid #e4e4e4;
      border-radius: 4px;
      height: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      background-color: #fff;
      overflow-y: auto;
      z-index: 9;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
      .navigation_top {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        color: #1a79f6;
        border-bottom: 0.5px solid #e4e4e4;
        padding: 20px;
        box-sizing: border-box;
      }
      .navigation_content {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;
        .childback{
          padding: 0px 5px;
          font-size: 16px;
          cursor: pointer;
        }
       
        .el-collapse {
          width: 100%;
          border: none;
          border-left: #d4d4d4 1px solid;
          padding: 10px;
          position: relative;
          .first_round,
          .second_round {
            position: absolute;
            left: -8px;
            width: 13px;
            height: 13px;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #d4d4d4;
            z-index: 999;
          }
          .first_round {
            top: -10px;
          }
          .second_round {
            bottom: -10px;
          }
        }
        ::v-deep .el-collapse-item__header {
          border: none;
        }
        // ::v-deep .el-collapse-item__wrap {
        //   display: none;
        // }
        ::v-deep .is-active {
          color: #4e93fb;
         
        }
        .nav_level_one,
        .nav_level_two,
        .nav_level_three {
          position: relative;
          .li_round {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #d4d4d4;
            border: 1px solid #d4d4d4;
            position: absolute;
            top: 22px;
            left: -14px;
            z-index: 999;
          }
          .triangle_box {
            position: absolute;
            top: 18px;
            left: -14px;
            //在三角形底边设置一个边界颜色
            //其它3边设置相同颜色
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 10px solid #4e93fb;
            border-right: 10px solid transparent;
          }
        }

        .nav_level_one {
          font-size: 16px;
        }
        .nav_level_two {
          font-size: 14px;
        }
        .nav_level_three {
          font-size: 12px;
        }
        .not_icon ::v-deep i {
          display: none;
        }
        // .active_title::v-deep .is-active:first-child {
        //   color: #4e93fb;
        //   background-color: #fff !important;
        // }

        .left_border_box_list {
          position: relative;
          height: 100%;
          border-left: 1px solid #d4d4d4;
          box-sizing: border-box;
          // margin-right: 10px;
        }
      }
    }
  }
  .center_right {
    width: 70%;
    height: calc(100vh - 291px - 103px);
    box-sizing: border-box;
    padding-top: 10px;
    overflow-y: auto;
    /* 滚动条整体部分 */
    &::-webkit-scrollbar {
      width: 3px;
      height: 9px;
    }
    /* 滚动条里面的滑块 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      transition: background-color 0.3s;
    }
    .right_title {
      color: #333333;
      font-size: 16px;
    }
  }
}
.new_page {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}
.color_green {
  background-color: #0ecbc6;
}
.color_blue {
  background-color: rgb(68, 138, 255);
}
.color_purple {
  background-color: #6a70ff;
}
.color_gray {
  background-color: #a1a1a1;
}
.type_tag {
  font-size: 14px;
  color: #559eff;
  // text-align: center;
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #559eff;
}
.type_tag1 {
  font-size: 14px;
  color: #559eff;
  // text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #559eff;
}
.row_tag {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
}
.btn_color {
  color: #448aff;
  border-color: #448aff;
}
.back {
  background-color: rgb(221, 234, 255);
  cursor: pointer;
  font-size: 16px;
  color: #4e93fb;
}
</style>
